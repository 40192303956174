import React from "react"
import SEO from "layout/SEO"
import Layout from "../components/Layout/Layout"
import HomeBanner from "components/StaticPages/Home/HomeBanner"
import HomeCard from "components/StaticPages/Home/HomeCard"
import HomeProcessDesktop from "components/StaticPages/Home/HomeProcessDesktop"
import HomeProcessMobile from "components/StaticPages/Home/HomeProcessMobile"
import Container from "layout/Container"
import ContactUs from "components/StaticPages/HelpCenter/ContactUs"

export default () => {
  return (
    <Layout>
      <SEO title="Home" />
      <HomeBanner />
      <HomeProcessDesktop />
      <HomeProcessMobile />
      <HomeCard />
      {/* <Container isCentered>
        <ContactUs />
      </Container> */}
    </Layout>
  )
}
