import React, { useEffect } from "react"
import classNames from "classnames"
import { useStaticQuery, graphql, navigate } from "gatsby"

import Hero from "layout/Hero"
import Container from "layout/Container"
import Button from "elements/Button"

import styles from "../utils/staticPages.module.scss"
import { animate } from "../../../services/animations"

const HomeBanner = () => {
  useEffect(() => {
    animate({
      x: -100,
      type: "from",
      target: ".fade-from-left",
      duration: 1,
    })
  }, [])

  const data = useStaticQuery(graphql`
    {
      heart: file(relativePath: { eq: "index__hero--heart.jpg" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const heart = data.heart.childImageSharp.fluid.src

  return (
    <Hero
      size="medium"
      background={{
        image: heart,
        position: "center",
      }}
    >
      <Container
        isCentered
        desktop={10}
        fullhd={8}
        customClassName="fade-from-left"
      >
        <h1
          className={classNames("title has-text-primary", styles["bannerText"])}
        >
          Delivering healthcare <br />
          everywhere
        </h1>
        <div className={classNames(styles["buttons"])}>
          <Button
            color="primary"
            size="medium"
            onClick={() => navigate("/epharmacy/order")}
          >
            Order Medicines
          </Button>
        </div>
      </Container>
    </Hero>
  )
}

export default HomeBanner
