import React, { useEffect } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import Container from "layout/Container"
import Brand from "../../Elements/Brand"
import Hero from "layout/Hero"

import styles from "../utils/staticPages.module.scss"
import { animate } from "../../../services/animations"

const HomeCard = () => {
  useEffect(() => {
    animate({
      y: 50,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-to-upper-corporate",
      animateOn: ".animate-corporate-trigger",
    })
    animate({
      y: 50,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-to-upper-careers",
      animateOn: ".animate-careers-trigger",
    })
  }, [])

  return (
    <Hero>
      <Container isCentered desktop={10} fullhd={8}>
        <div className={classNames(styles["card"])}>
          <div className="card-content">
            <div className="columns">
              <div className="column px-2 mb-3">
                <span className="has-text-dark is-uppercase fade-to-upper-corporate">
                  Corporate
                </span>
                <h1 className="title mt-0 has-text-primary fade-to-upper-corporate">
                  What we can do
                </h1>
                <hr
                  className={classNames(
                    "fade-to-upper-corporate",
                    styles["card__divider"]
                  )}
                />
                <p className="fade-to-upper-corporate">
                  <Brand />
                  's online pharmacy, clinic management services, and patient
                  support programs enable patients and companies to get their
                  medicines conveniently, cost-effectively, and intelligently.
                </p>
                <p className="mt-1">
                  <Link
                    className="has-text-weight-bold has-text-primary fade-to-upper-corporate"
                    to="/corporate"
                  >
                    Learn more{" "}
                  </Link>
                </p>
                <section className="animate-corporate-trigger"></section>
              </div>

              <div className="column px-2 mb-3">
                <span className="has-text-dark is-uppercase fade-to-upper-careers">
                  Careers
                </span>
                <h1 className="title mt-0 has-text-secondary fade-to-upper-careers">
                  Grow with your goals
                </h1>
                <hr
                  className={classNames(
                    "fade-to-upper-careers",
                    styles["card__divider"]
                  )}
                />
                <p className="fade-to-upper-careers">
                  <Brand />
                  's dynamic environment and flexible roles maximizes our team's
                  impact, growth, and fun.
                </p>
                <p className="mt-1 fade-to-upper-careers">
                  <Link
                    className="has-text-weight-bold has-text-secondary fade-to-upper-careers"
                    to="/careers"
                  >
                    Learn more{" "}
                  </Link>
                </p>
                <section className="animate-careers-trigger"></section>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Hero>
  )
}

export default HomeCard
